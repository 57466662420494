
import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
const FundsPendingDeposit = () => {

    const { SearchBar } = Search;
    const [fundsDeposit, setFundsDeposit] = useState([]);
    const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <>{moment(row?.createdAt).format("MMM Do YYYY")}</>;
    };
    const columns = [
        { dataField: 'createdAt', text: 'Chain', formatter: dateFormatter },
        { dataField: 'chain', text: 'Chain' },
        { dataField: 'currency', text: 'Currency' },
        { dataField: 'emailId', text: 'Email', },
        { dataField: 'firstName', text: 'First Name', },
        { dataField: 'lastName', text: 'Last Name', },
        { dataField: 'from_address', text: 'From Address', },
        { dataField: 'to_address', text: 'To Wallet Address', },
        { dataField: 'transaction_hash', text: 'Transaction Hash', },
        { dataField: 'status', text: 'Status', },
        { dataField: 'amount', text: 'Amount', sort: true, },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
        handleFundDeposit()
    }, []);

    const handleFundDeposit = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.completePendingRequest().then(async result => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setFundsDeposit(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Pending Deposit
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Main page content */}
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Pending Deposit
                            {fundsDeposit.length === 0 ? "" :
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export{" "}
                                    </button>
                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={fundsDeposit} className="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            }
                        </div>
                        <>
                            {
                                fundsDeposit.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" />  <br />No Data Available</h6> :
                                    <div className="card-body mt-3">
                                        <table className="" width="100%" >
                                            <ToolkitProvider
                                                hover
                                                bootstrap4
                                                keyField='_id'
                                                columns={columns}
                                                data={fundsDeposit}
                                                exportCSV
                                                search={{
                                                    afterSearch: (newResult) => console.log(newResult)
                                                }}
                                            >
                                                {
                                                    props => (
                                                        <React.Fragment>
                                                            <SearchBar {...props.searchProps} />
                                                            <BootstrapTable
                                                                hover
                                                                bootstrap4
                                                                keyField='_id'
                                                                columns={columns}
                                                                data={fundsDeposit}
                                                                pagination={pagination}
                                                                filter={filterFactory()}
                                                                {...props.baseProps}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </table>
                                    </div>
                            }
                        </>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default FundsPendingDeposit;