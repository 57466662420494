import Swal from "sweetalert2";

function alertErrorMessage(message) {
  Swal.fire('', message, 'error');
}

function alertSuccessMessage(message) {
  Swal.fire('', message, 'success');
}
function alertWarningMessage(message) {
  Swal.fire('', message, 'warning');
}

export { alertErrorMessage,alertSuccessMessage,alertWarningMessage };