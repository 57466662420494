import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";

import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { parse } from "date-fns";
import { $ } from "react-jquery-plugin";

const OrderBook = () => {

    const { SearchBar } = Search;
    const [orderBookDetails, setorderBookDetails] = useState([]);
    const [tradeDetails, settradeDetails] = useState([]);
    console.log(tradeDetails, 'tradeDetails');

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.filled && parseFloat(row?.filled?.toFixed(8))}
            </>
        );
    };
    const QuantityFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.quantity && parseFloat(row?.quantity?.toFixed(8))}
            </>
        );
    };
    const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <>{moment(row?.updatedAt).format("DD/MM/YYYY")}</>;
    };

    const Modal = (cell, row, rowIndex, formatExtraData) => {
        return <span className="cursor_pointer" onClick={() => { $("#Trades_modal").modal("show"); tradeById(row?._id) }}> {row?._id}</span>
    };
    const tradeById = async (id) => {
        settradeDetails([])
        LoaderHelper.loaderStatus(true);
        await AuthService.tradeById(id).then(
            async (result) => {
                if (result.success) {
                    settradeDetails(result?.data)
                    LoaderHelper.loaderStatus(false);

                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            }
        );
    };

    const columns = [
        { dataField: 'user_id', text: 'User Id', },
        { dataField: '_id', text: 'Order Id', formatter: Modal, },
        { dataField: 'updatedAt', text: 'Date/Time', formatter: dateFormatter, },
        { dataField: 'main_currency', text: 'Currency', },
        { dataField: 'order_type', text: 'Order Type', },
        { dataField: 'filled', text: 'Filled', sort: true, formatter: linkFollow },
        { dataField: 'maker_fee', text: 'Maker Fee', sort: true, },
        { dataField: 'taker_fee', text: 'Taker Fee', sort: true, },
        { dataField: 'price', text: 'Price', sort: true, },
        { dataField: 'remaining', text: 'Remaining', sort: true, },
        { dataField: 'status', text: 'Status', },
        { dataField: 'side', text: 'Side', },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
        HandleOrderBook()
    }, []);

    const HandleOrderBook = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.OrderBook().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setorderBookDetails(result?.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="far fa-user"></i></div>
                                            OrderBook
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-header">
                                OrderBook Details
                                {orderBookDetails.length === 0 ? "" :
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Export{" "}
                                        </button>
                                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={orderBookDetails} className="dropdown-item">Export as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>
                            <>
                                {
                                    orderBookDetails.length === 0 ? <h6 className="ifnoData"><img alt="" src="assets/img/no-data.png" />
                                        <br />No Data Available</h6> :
                                        <div className="card-body">
                                            <table width="100%" >
                                                <ToolkitProvider
                                                    hover
                                                    bootstrap4
                                                    keyField='_id'
                                                    columns={columns}
                                                    data={orderBookDetails}
                                                    exportCSV
                                                    search={{
                                                        afterSearch: (newResult) => console.log(newResult)
                                                    }}>
                                                    {
                                                        props => (
                                                            <React.Fragment>
                                                                <SearchBar {...props.searchProps} />
                                                                <BootstrapTable
                                                                    hover
                                                                    bootstrap4
                                                                    keyField='_id'
                                                                    columns={columns}
                                                                    data={orderBookDetails}
                                                                    pagination={pagination}
                                                                    filter={filterFactory()}
                                                                    {...props.baseProps}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </ToolkitProvider>
                                            </table>
                                        </div>
                                }
                            </>

                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal"
                id="Trades_modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="Trades_modal_modalTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog  alert_modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">
                                Trade Details
                            </h5>
                            <button
                                className="btn-close"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {tradeDetails.length > 0 ? tradeDetails?.map((item, index) => {
                                return (
                                    <>
                                        <div className="form-group position-relative ">
                                            <label className="small">Currency: {item?.currency} </label>
                                        </div>
                                        <div className="form-group   position-relative ">
                                            <label className="small ">Price: {item?.price} </label>
                                        </div>
                                        <div className="form-group position-relative ">
                                            <label className="small mb-1">Quantity: {item?.quantity} </label>
                                        </div>
                                        <hr />
                                    </>
                                )
                            }) : 'No Data Found'}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderBook;