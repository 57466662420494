import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { ProfileContext } from "../../../context/ProfileProvider";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const LoginPage = () => {
    const navigate = useNavigate();
    const [, updateProfileState] = useContext(ProfileContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "email":
                setEmail(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            default:
        }
    }

    const handleGetOtp = async (signId,) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getOtpresend(signId).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const handleLogin = async (email, password, otp) => {
        // navigate('/dashboard');
        LoaderHelper.loaderStatus(true);
        await AuthService.login(email, password, otp).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    sessionStorage.setItem("token", result.data.token);
                    sessionStorage.setItem("emailId", result.data.email_or_phone);
                    sessionStorage.setItem("userType", result.data.admin_type);
                    sessionStorage.setItem("userId", result.data.id);
                    sessionStorage.setItem("permissions", JSON.stringify(result?.data?.permissions || []));
                    updateProfileState(result.data);
                    alertSuccessMessage(result.message);
                    navigate('/dashboard');
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div className="card my-5">
                                    <div className="card-body p-5 text-center">
                                        <img src="assets/img/logo-white.png" className="img-fluid" alt="" width='200' />
                                    </div>
                                    {/* <hr className="my-0" /> */}
                                    <div className="card-body p-3">
                                        <form>
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="emailExample">Email address</label>
                                                <div className="input-group">
                                                    <input className="form-control form-control-solid" type="email" name="email" placeholder="" aria-label="Email Address" aria-describedby="emailExample" value={email} onChange={handleInputChange} />
                                                    <button type="button" onClick={() => { handleGetOtp(email) }} className="btn btn-block btn-xl btn-warning btn_admin" >
                                                        <span> GET OTP </span>
                                                    </button>
                                                </div>

                                            </div>
                                            {console.log(otp, 'otp')}
                                            <label className="text-gray-600 small" for="otp">Enter the OTP</label>
                                            <input className="form-control form-control-solid" type="email" name="email" placeholder="" aria-label="Email Address" aria-describedby="emailExample" value={otp}
                                                onChange={(e) => setOtp(e.target.value)} />
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="passwordExample">Password</label>
                                                <input className="form-control form-control-solid" type="password" placeholder="" aria-label="Password" name="password" aria-describedby="passwordExample" value={password} onChange={handleInputChange} />
                                            </div>
                                            <div>
                                                <Link className="btn-link text-decoration-none" to="/forgotpassword">Forgot your password?</Link>
                                            </div>
                                            <div className="text-center py-3 mt-2">
                                                <button type="button" className="btn btn-block w-100 btn-xl btn-warning btn_admin mt-2 px-5"
                                                    onClick={() => handleLogin(email, password, otp)}>
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default LoginPage;