import React, { useEffect, useState } from "react";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import { CSVLink } from "react-csv";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import TodayRegestration from "../TodayRegestration";
import { Link } from "react-router-dom";
import moment from "moment";

const TodayRegestrationDetails = (props) => {
    const [tranactionData, setTranactionData] = useState([]);
    const { SearchBar } = Search;
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [verifiStatus, setVerifiStatus] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [verificationDate, setVerificationDate] = useState('');
    const [kyctype, setKyctype] = useState('');
    const [lastName, setLastName] = useState('');
    const [selfie, setSelfie] = useState([])
    const [frontImg, setFrontImg] = useState([])
    const [backImg, setBackImg] = useState([])
    const [panImg, setPanImg] = useState([])
    const [acNumber, setAcNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [branch, setBranch] = useState('');
    const [holderName, setHolderName] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [activeScreen, setActiveScreen] = useState('todayList');
    const [profilePicture, setProfilePicture] = useState([]);
    const [showImage, setShowImage] = useState('');

    useEffect(() => {
        // handleUserData();
        // handleTransferhistory();
    }, [])

    const handleUserData = async () => {
        await AuthService.allKycData(props.userId).then(async result => {
            if (result?.data.length > 0) {
                try {
                    setFirstName(result.data[0]?.firstName);
                    setEmail(result.data[0]?.emailId);
                    setMobileNumber(result.data[0]?.mobileNumber);
                    setGender(result.data[0]?.gender);
                    setAddress(result.data[0]?.address?.line1);
                    setCity(result.data[0]?.address?.city);
                    setState(result.data[0]?.address?.state);
                    setZipCode(result.data[0]?.address?.zipcode);
                    setVerifiStatus(result.data[0]?.kycVerified);
                    setCreatedAt(result.data[0]?.createdAt);
                    setVerificationDate(result.data[0]?.updatedAt);
                    setKyctype(result.data[0]?.kyctype);
                    setLastName(result.data[0]?.lastName);
                    setSelfie(result.data[0]?.selfiePath);
                    setFrontImg(result.data[0]?.identityPath?.front);
                    setBackImg(result.data[0]?.identityPath?.back);
                    setPanImg(result.data[0]?.incomePath);
                    setAcNumber(result.data[0]?.bankDetails?.AcNumber);
                    setBankName(result.data[0]?.bankDetails?.BankName);
                    setBranch(result.data[0]?.bankDetails?.Branch);
                    setHolderName(result.data[0]?.bankDetails?.HolderName);
                    setIfsc(result.data[0]?.bankDetails?.ifsc);
                    setProfilePicture(result.data[0]?.profilepicture);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message)
            }
        })
    }

    const columns = [
        { dataField: '_id', text: 'Transaction ID' },
        { dataField: 'amount', text: 'Amount', sort: true },
        { dataField: 'coinSymbol', text: 'Coin Name', sort: true, },
        { dataField: 'transType', text: 'Type', sort: true, },
        { dataField: 'status', text: 'Status', sort: true },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    const handleTransferhistory = async () => {
        await AuthService.transferhistory(props.userId).then(async result => {
            if (result?.message === "") {
                try {
                    setTranactionData(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    const handleShowImage = (img) => {
        setShowImage(img);
    }

    return (
        activeScreen === 'todayList' ?
            <>
                <div id="layoutSidenav_content">
                    <main>
                        <form className="form-data" >
                            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                                <div className="container-xl px-4">
                                    <div className="page-header-content pt-4">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-auto mt-4">
                                                <h1 className="page-header-title">
                                                    <Link to="" className="page-header-icon" onClick={() => setActiveScreen('userdetails')}><i className="fa fa-arrow-left" ></i></Link>
                                                    {firstName} {lastName}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="container-xl px-4 mt-n10" >
                                <div className="row" >
                                    <div className="col-xl-4">
                                        <div className="card mb-4 mb-xl-0">
                                            <div className="card-body py-5">
                                                {/* <!-- Profile picture image--> */}
                                                <div className="text-center">

                                                    {!selfie ? <img className="img-account-profile rounded-circle mb-4" src="assets/img/illustrations/profiles/profile-5.png" alt="profilePicture" /> :

                                                        /*  <img className="img-account-profile rounded-circle mb-4" src={ApiConfig?.uploadUrl + profilePicture} alt="profilePicture" /> */

                                                        <img className="img-account-profile rounded-circle mb-4" src={ApiConfig?.uploadUrl + selfie} alt="Selfie" />
                                                    }


                                                    <h3 className="fw-bolder fs-2 mb-0">
                                                        {firstName} {lastName}
                                                    </h3>
                                                    <div className=" text-gray">
                                                        <small>{email}</small>
                                                    </div>
                                                </div>
                                                <div className=" py-5 px-4 ">
                                                    <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                                                        <li className="nav-item  ">
                                                            <a className="menu-link  nav-link active" id="personal-pill" href="#personalPill" data-bs-toggle="tab" role="tab" aria-controls="personal" aria-selected="true">
                                                                <span className="menu-bullet">{/* <span className="bullet"></span> */}
                                                                </span><span className="menu-title">  Personal Information </span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item  ">
                                                            <a className="menu-link nav-link" id="Verification-pill" href="#VerificationPill" data-bs-toggle="tab" role="tab" aria-controls="Verification" aria-selected="false"> <span className="menu-bullet">{/* <span className="bullet"></span> */}
                                                            </span><span className="menu-title">  KYC Verification</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link  nav-link" id="bankaccount-pill" href="#bankaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet">{/* <span className="bullet"></span> */}
                                                            </span><span className="menu-title">  Bank Account Details</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link  nav-link" id="Transitions-pill" href="#TransitionsPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet">{/* <span className="bullet"></span> */}
                                                            </span><span className="menu-title" >  User Transition</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8" >
                                        <div className="tab-content tab-content-card" id="cardPillContent">
                                            {/* personal INformation */}
                                            <div className="tab-pane card show active" id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                                                {/* <!-- Profile picture help block--> */}
                                                <div className="card-header">Personal Information</div>
                                                <div className="card-body" >
                                                    <div className="profile_data py-4 px-4" >
                                                        <div className="row" > <span className="col-3" >Name: </span><strong className="col text-dark ">{firstName}</strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Email:</span> <strong className=" col">{email}</strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Mobile Number:</span> <strong className="col"> {mobileNumber} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Gender:</span> <strong className=" col"> {gender} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3 " >Address:</span> <strong className="col"> {address} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >City:</span> <strong className=" col"> {city} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >State:</span> <strong className=" col"> {state}  </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Zip Code:</span> <strong className=" col"> {zipCode} </strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* KYC Verification */}
                                            <div className="tab-pane card" id="VerificationPill" role="tabpanel" aria-labelledby="Verification-pill">
                                                <div className="card-header">KYC Verification Details</div>
                                                <div className="card-body" >
                                                    <div className="profile_data py-4 px-4" >
                                                        <div className="row" >
                                                            <span className="col-3" >Verifivation Status: </span>
                                                            {verifiStatus == "2" ?
                                                                <strong className=" col text-success  ">Verified</strong>
                                                                : <strong className=" col text-warning ">Pending</strong>
                                                            }
                                                        </div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Registration Date:</span> <strong className=" col">{moment(createdAt).format('ll')}</strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Verification Date:</span> <strong className=" col">{moment(verificationDate).format('ll')} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >KYC Type:</span> <strong className=" col"> {kyctype} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row">
                                                            <span className="mb-4 col-12" >Document Verification:</span>
                                                            <div className="col-6  mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col">Other Identity <small> (Front) </small>
                                                                        </div>
                                                                    </div>
                                                                    {!frontImg.length > 0 ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-50" src="assets/img/illustrations/profiles/nophoto.png" style={{ marginLeft: '70px' }} /></div> :

                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + frontImg} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleShowImage(ApiConfig?.uploadUrl + frontImg)} />
                                                                        </div>
                                                                    }

                                                                    {/* <a className="ratio ratio-16x9" href={ApiConfig?.uploadUrl + frontImg} target="_blank">
                                                                        <img src={ApiConfig?.uploadUrl + frontImg} className="w-100" />
                                                                    </a> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col"> Other Identity <small> (Back) </small> </div>
                                                                    </div>

                                                                    {!backImg.length > 0 ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-50" src="assets/img/illustrations/profiles/nophoto.png" style={{ marginLeft: '70px' }} /></div> :

                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + backImg} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleShowImage(ApiConfig?.uploadUrl + backImg)} />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col"> Other Identity </div>
                                                                    </div>

                                                                    {!panImg ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-50" src="assets/img/illustrations/profiles/nophoto.png" style={{ marginLeft: '70px' }} /></div> :

                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + panImg} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleShowImage(ApiConfig?.uploadUrl + panImg)} />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col"> Selfie </div>
                                                                    </div>

                                                                    {!selfie ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-50" src="assets/img/illustrations/profiles/nophoto.png" style={{ marginLeft: '70px' }} /></div> :

                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + selfie} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleShowImage(ApiConfig?.uploadUrl + selfie)} />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Bank Account Details*/}
                                            <div className="tab-pane card" id="bankaccountPill" role="tabpanel" aria-labelledby="bankaccount-pill">
                                                <div className="card-header">Bank Account Details</div>
                                                <div className="profile_data py-5 px-4" >
                                                    <div className="row" >
                                                        <span className="col-3" >Bank Name: </span>
                                                        <strong className=" col text-success "> {/* <i className="fa fa-check-circle"></i> */} {bankName}  </strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Bank Account Number:</span> <strong className=" col">{acNumber}</strong></div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Account Holder Name:</span> <strong className=" col"> {holderName} </strong></div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Branch Name:</span> <strong className=" col"> {branch} </strong></div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >IFSC Code:</span> <strong className=" col"> {ifsc} </strong></div>
                                                </div>
                                            </div>
                                            {/* User Transitions*/}
                                            <div className="tab-pane card" id="TransitionsPill" role="tabpanel" aria-labelledby="Transitions-pill">
                                                <div className="card-header">User Transition
                                                    {tranactionData.length === 0 ? "" :
                                                        <div className="dropdown">
                                                            <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                            <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                                <CSVLink data={tranactionData} className="dropdown-item">Export as CSV</CSVLink>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <>
                                                    {tranactionData.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :
                                                        <div className="card-body" >
                                                            <form className="row">
                                                                <div className="col-12" >
                                                                    <table className="" width="100%" >
                                                                        <ToolkitProvider
                                                                            hover
                                                                            bootstrap4
                                                                            keyField='_id'
                                                                            columns={columns}
                                                                            data={tranactionData}
                                                                            search >
                                                                            {
                                                                                props => (
                                                                                    <React.Fragment>
                                                                                        <SearchBar {...props.searchProps} />
                                                                                        <BootstrapTable
                                                                                            hover
                                                                                            bootstrap4
                                                                                            keyField='_id'
                                                                                            columns={columns}
                                                                                            data={tranactionData}
                                                                                            pagination={pagination}
                                                                                            filter={filterFactory()}
                                                                                            {...props.baseProps}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        </ToolkitProvider>
                                                                    </table>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    }
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </main>
                    {/* Trade Image Detail */}
                    <div className="modal image_modal" id="edit_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog  alert_modal modal-lg" role="document">
                            <div className="modal-content">
                                <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                <img src={showImage} className="w-100" alt="" />

                            </div>
                        </div>
                    </div>
                    {/* Trade Image Detail  */}
                </div>
            </>
            : <TodayRegestration />

    )


}

export default TodayRegestrationDetails;