import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TraderDetails from "../TraderDetails";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const TradeList = (props) => {

  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [userId, setUserId] = useState("");
  const { SearchBar } = Search;
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [kycStatus, setkycStatus] = useState();

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="btn btn-dark btn-sm   me-2" onClick={() => { setUserId(row?._id); setActiveScreen("detail"); setkycStatus(row?.kyc_verified); }}>
          View
        </button>
      </div>
    );
  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row?.firstName} {row?.lastName}
      </>
    );
  };

  const dateFilter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };
  const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button className={`${row?.status === "Active" ? "btn-success" : "btn-danger"} btn btn-sm  `} style={{ marginLeft: "20px" }} onClick={() => handleStatus(row?._id, row?.status === "Active" ? "Inactive" : "Active")}>{row?.status}
        </button>

      </>
    );
  };
  const statuslinkFollow2 = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button className={`${row?.wallet_status === "INACTIVE" ? "btn-danger" : "btn-success"} btn btn-sm  `} style={{ marginLeft: "20px" }} onClick={() => handleWalletStatus(row?._id, row?.wallet_status === "INACTIVE" ? "ACTIVE" : "INACTIVE")}>{row?.wallet_status ? row?.wallet_status : "ACTIVE"}
        </button>

      </>
    );
  };
  const columns = [
    { dataField: "_id", text: "User ID" },
    { dataField: "Name", text: "Name", sort: true, formatter: nameFormatter },
    { dataField: "emailId", text: "Email", sort: true },
    { dataField: "mobileNumber", text: "Phone Number", sort: true },
    { dataField: "createdAt", text: "Registration Date", sort: true, formatter: dateFilter, },
    { dataField: "status", text: " Status", sort: true, formatter: statuslinkFollow, },
    { dataField: "wallet_status", text: " Wallet Status", sort: true, formatter: statuslinkFollow2, },
    { dataField: "Action", text: "Action", formatter: linkFollow },



  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  useEffect(() => {
    handleExportData();
  }, []);

  const handleStatus = async (_id, status) => {
    await AuthService.updateStatus(_id, status).then(
      async (result) => {
        if (result.success) {
          try {
            handleExportData();
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleWalletStatus = async (_id, status) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.tradeWalletStatus(_id, status).then(
      async (result) => {
        LoaderHelper.loaderStatus(false)
        if (result.success) {
          try {
            alertSuccessMessage(result.message);
            handleExportData();
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleData = async () => {
    LoaderHelper.loaderStatus(true)
    await AuthService.getusers().then(async (result) => {
      if (result.data.length > 0) {
        try {
          setExportData(result?.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };



  const handleExportData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getExportList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.data.reverse());
          setallData(result?.data);
        } catch (error) {
          alertErrorMessage(error);

        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");

      }
    });
  };
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setExportData(filteredData?.reverse())
  }
  const ResetfilterDate = () => {
    setFromDate('')
    setToDate('')
    setExportData(allData)
  }

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Traders List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="filter_bar">
            <form className="row">
              <div className="mb-3 col ">
                <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
              </div>
              <div className="mb-3 col ">
                <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate} onChange={(e) => { setToDate(e.target.value); }} />
              </div>
              <div className="mb-3 col ">
                <div className="row">
                  <div className="col">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                  <div className="col">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={ResetfilterDate}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              Traders Details
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-sm dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <ToolkitProvider
                  hover
                  bootstrap4
                  keyField="_id"
                  columns={columns}
                  data={exportData}
                  exportCSV
                  search
                >
                  {(props) => (
                    <React.Fragment>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        hover
                        bootstrap4
                        keyField="_id"
                        columns={columns}
                        data={exportData}
                        pagination={pagination}
                        filter={filterFactory()}
                        {...props.baseProps}
                      />
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <TraderDetails userId={userId} kycStatus={kycStatus} />
  );
};

export default TradeList;
