import React, { useState, useEffect, useRef } from "react";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import SupportPage from "../SupportPage";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import { Link } from "react-router-dom";
const Supportmessage = (props) => {

  const { userId, id, email, description } = props;
  const [activeScreen, setActiveScreen] = useState('supportmessage');
  const [message, setMessage] = useState('');
  const [messageQuery, setMessageQuery] = useState([]);
  const messagesEndRef = useRef(null)

  const handleInputSellChange = (event) => {
    switch (event.target.name) {
      case "message":
        setMessage(event.target.value);
        break;
      default:
        break;
    }
  }


  const handleMessageQuery = async (message, userId, id) => {
    LoaderHelper.loaderStatus(false);
    await AuthService.addAdTicket(message, userId, id).then(async result => {
      if (result.msg === "Response Submitted") {
        try {
          LoaderHelper.loaderStatus(false);
          setMessage("");
          getMessageQuery();
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.msg);
      }
    });
  }

  useEffect(() => {
    // getMessageQuery();
    // handleChangeseen();
  }, []);

  const getMessageQuery = async () => {
    // LoaderHelper.loaderStatus(false);
    await AuthService.ticketList(userId, id).then(async result => {
      if (result.success) {
        try {
          LoaderHelper.loaderStatus(false);
          // alertSuccessMessage(result.success);
          setMessageQuery(result?.data[0]?.ticket)
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.success);
      }
    });
  }

  useEffect(() => {
    // scrollToBottom()
  }, [messageQuery]);


  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView(false)
  }

  const handleChangeseen = async () => {
    // LoaderHelper.loaderStatus(false);
    await AuthService.getChangeScreen(userId, id).then(async result => {
      if (result.msg) {
        LoaderHelper.loaderStatus(false);
        try {
          /*  alertSuccessMessage(result.msg); */
          /* setChangeScreen(result) */
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.msg);
      }
    });
  }


  return (
    activeScreen === 'supportmessage' ?
      <>
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <Link to="" onClick={() => setActiveScreen('support')}>
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                        <div className="page-header-icon"></div>
                        Support Message
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="container-xl px-4 mt-n10">
              <div className="row" >
                {/* description */}
                <div className="col-xl-4">
                  <div className="card mb-4">
                    <div className="card-header">Description
                      <div className="dropdown"></div>
                    </div>
                    {description.length === 0 ? <h6 style={{ textAlign: 'center', padding: '164px 0' }}>No Description Available</h6> :
                      <div className="card-body mt-3">
                        <div className="right">
                          <div className="middle">
                            <div className="tumbler">
                              <div className="messages" >

                                {description}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                {/*   handle Message */}
                <div className="col-xl-8">
                  <div className="card mb-4">
                    <div className="card-header">{email}
                      <div className="dropdown"></div>
                    </div>
                    <div className="card-body mt-3">
                      <div className="right">
                        <div className="middle">
                          <div className="tumbler">
                            <div className="messages" >
                              {messageQuery.length > 0 ?
                                messageQuery.map(item =>
                                  <div ref={messagesEndRef}>
                                    {item?.replyBy === 1 ?
                                      <div className="clip received">
                                        <div className="text">{item?.query}</div>
                                      </div>
                                      :
                                      <div className="clip sent">
                                        <div className="text">{item?.query}</div>
                                      </div>
                                    }
                                  </div>
                                )
                                : null}

                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="cup">
                            <input type="text" id="message" cols="30" rows="1" placeholder="Message..." name="message" value={message} onChange={handleInputSellChange} />
                            <button className="send" onClick={() => handleMessageQuery(message, userId, id)}>Send</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>
      </>
      : <SupportPage />
  )
}

export default Supportmessage;
