import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
const Notification = () => {

    const { SearchBar } = Search;
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notification, setNotification] = useState('');
    const [notificationLink, setNotificationLink] = useState('');
    const [notificationList, setNotificationList] = useState('');
    console.log(notificationLink, 'notificationLink');
    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "notificationTitle":
                setNotificationTitle(event.target.value);
                break;
            case "notification":
                setNotification(event.target.value);
                break;
            case "notificationLink":
                setNotificationLink(event.target.value);
                break;
            default:
        }
    }

    const resetInputChange = () => {
        setNotificationTitle("");
        setNotification("");
        setNotificationLink("");
    }

    const handleNotify = async (notificationTitle, notification, notificationLink) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.addNotify(notificationTitle, notification, notificationLink).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    resetInputChange();
                    handleNotification();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    }
    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-danger btn-sm" type="button" onClick={() => DeleteNotification(row?._id)}>Delete</button>
            </>
        );
    };
    const columns = [
        { dataField: '_id', text: 'ID' },
        { dataField: 'title', text: 'Notification Title', sort: true, },
        { dataField: 'message', text: 'Notification', sort: true, },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
        handleNotification()
    }, []);

    const handleNotification = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getNotificationList().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setNotificationList(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("Something Went Wrong");
            }
        });
    }
    const DeleteNotification = async (id) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.deleteNotify(id).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message)
                    handleNotification()
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("Something Went Wrong");
            }
        });
    }
    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-bell"></i></div>
                                            Notifications
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row" >
                            <div className="col-xl-4">
                                <div className="card mb-4 mb-xl-0">
                                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                                            <h5 className="mb-0" >Send Notification</h5>
                                        </div>
                                        <form>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1"> Notification Title </label>
                                                <input className="form-control  form-control-solid" type="text" placeholder="Enter Title" name="notificationTitle" value={notificationTitle} onChange={handleInputChange}></input>
                                            </div>

                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1"> Notification </label>
                                                <textarea className="form-control form-control-solid" rows={3} name="notification" value={notification} onChange={handleInputChange}></textarea>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1"> Notification Link (optional) </label>
                                                <input className="form-control  form-control-solid" type="text" placeholder="Notification Link" name="notificationLink" value={notificationLink} onChange={handleInputChange}></input>
                                            </div>
                                            <button className="btn btn-indigo   btn-block w-100 mt-2" type="button" onClick={() => handleNotify(notificationTitle, notification, notificationLink)} disabled={!notificationTitle} > Send Notification </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8" >
                                <div className="card">
                                    <div className="card-header">Notification List
                                        {notificationList.length === 0 ? "" :
                                            <div className="dropdown">
                                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                    <CSVLink data={notificationList} className="dropdown-item">Export as CSV</CSVLink>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="card-body" >
                                        <form className="row" >
                                            <div className="col-12" >
                                                <table className="" width="100%" >
                                                    {notificationList.length === 0 ? <h6 className="ifnoData">
                                                        <img src="assets/img/no-data.png" />
                                                        <br />No Data Available</h6> :
                                                        <ToolkitProvider
                                                            hover
                                                            bootstrap4
                                                            keyField='_id'
                                                            columns={columns}
                                                            data={notificationList}
                                                            exportCSV
                                                            search={{
                                                                afterSearch: (newResult) => console.log(newResult)
                                                            }} >
                                                            {
                                                                props => (
                                                                    <React.Fragment>
                                                                        <SearchBar {...props.searchProps} />
                                                                        <BootstrapTable
                                                                            hover
                                                                            bootstrap4
                                                                            keyField='_id'
                                                                            columns={columns}
                                                                            data={notificationList}
                                                                            pagination={pagination}
                                                                            filter={filterFactory()}
                                                                            {...props.baseProps}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </ToolkitProvider>
                                                    }
                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Notification;