import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const FundsPendingWithdrawal = () => {
  const { SearchBar } = Search;
  const [fundWithdrawal, setFundWithdrawal] = useState([]);

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("MMM Do YYYY")}</>;
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button
          className="btn btn-success btn-sm me-2"
          onClick={() => handleStatus(row?._id,"COMPLETE")}
        >
          Approve
        </button>

        <button
          className="btn btn-danger btn-sm "
          onClick={() => handleStatus(row?._id, "CANCELLED")}
        >
          Denied
        </button>
      </>
    );
  };

  const linkStatus = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <span
          className={`badge ${
            row?.status === "PENDING"
              ? "bg-warning"
              : row?.status === "CANCELLED"
              ? "bg-danger"
              : row?.status === "APPROVE"
              ? "bg-success"
              : row?.status === "SUCCESS"
              ? "bg-success"
              : ""
          } `}
        >
          {row?.status}
        </span>
      </>
    );
  };

  const columns = [
    { dataField: "createdAt", text: "Date", sort: true, formatter: dateFormatter, },
    { dataField: "emailId", text: "Email Id", sort: true },
    { dataField: "short_name", text: "Coin Name", sort: true },
    { dataField: "chain", text: "Chain", sort: true },
    { dataField: "user_id", text: "User Id", sort: true },
    { dataField: "to_address", text: "Withdrawal Address", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "status", text: "Status", sort: true, formatter: linkStatus },
    { dataField: "Action", text: "Action", formatter: linkFollow },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });


  useEffect(() => {
    handleFundWithdrawal();
  }, []);

  const handleFundWithdrawal = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.PendingWithdrwal().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setFundWithdrawal(result.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("Something Went Wrong");
      }
    });
  };

  const handleStatus = async (id, status) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.handleFundStatus(id, status).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        // alertSuccessMessage(result.data.msg);
        handleFundWithdrawal();
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

 


  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Pending Withdrawal
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main page content */}
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              Pending Withdrawal
              {fundWithdrawal.length === 0 ? (
                ""
              ) : (
                <div className="dropdown">
                  <button
                    className="btn btn-dark btn-sm dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export{" "}
                  </button>
                  <div
                    className="dropdown-menu animated--fade-in-up"
                    aria-labelledby="dropdownFadeInUp"
                  >
                    <CSVLink data={fundWithdrawal} className="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              )}
            </div>
            <>
              {fundWithdrawal.length === 0 ? (
                <h6 className="ifnoData">
                  <img src="assets/img/no-data.png" /> <br />
                  No Data Available
                </h6>
              ) : (
                <div className="card-body mt-3">
                  <table className="" width="100%">
                    <ToolkitProvider
                      hover
                      bootstrap4
                      keyField="_id"
                      columns={columns}
                      data={fundWithdrawal}
                      exportCSV
                      search={{
                        afterSearch: (newResult) => console.log(newResult),
                      }}
                    >
                      {(props) => (
                        <React.Fragment>
                          <SearchBar {...props.searchProps} />
                          <BootstrapTable
                            hover
                            bootstrap4
                            keyField="_id"
                            columns={columns}
                            data={fundWithdrawal}
                            pagination={pagination}
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </table>
                </div>
              )}
            </>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FundsPendingWithdrawal;
