import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const RejectedKyc = () => {
    const { SearchBar } = Search;
    const [data, setData] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "dateFrom":
                setDateFrom(event.target.value);
                break;
            case "dateTo":
                setDateTo(event.target.value);
                break;
            default:
        }
    }


    function aadharFormatter(cell, row) {
        return row?.kycdata?.document_type + '-' + row?.kycdata?.document_number;
    }

    function imageFormatter(cell, row) {
        return <img className="table-img" src={ApiConfig.appUrl + row?.user_selfie} alt="Selfy" />;
    }

    function dateFormatter(cell, row) {
        return moment(row?.createdAt).format('DD-MM-YYYY');
    }
    function userIdFormatter(cell, row) {
        return row?.kycdata?.userId;
    }
    function panFormatter(cell, row) {
        return row?.kycdata?.pancard_number;
    }
    function dobFormatter(cell, row) {
        return row?.kycdata?.dob
            ;
    }

    const columns = [
        { dataField: '_id', text: 'ID' },
        { dataField: 'first_name', text: 'FirstName', sort: true, },
        { dataField: 'last_name', text: 'LastName', sort: true, },
        { dataField: 'document_number', text: 'Document Number', sort: true },
        { dataField: 'pancard_number', text: 'Pan Number', sort: true },
        { dataField: 'user_selfie', text: 'Selfie', sort: true, formatter: imageFormatter },
        { dataField: 'dob', text: 'DOB', sort: true, },
        { dataField: 'createdAt', text: 'Created At', sort: true, formatter: dateFormatter },
    ]
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
        handleData()
    }, []);

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getdatarejectedlist().then(async result => {
            console.log(result, "resullllllllllllllllllttt");

            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setData(result.data.reverse());
                    setDateFrom("");
                    setDateTo("");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleExportData = async (dateFrom, dateTo) => {
        await AuthService.exportApprovedList(dateFrom, dateTo).then(async result => {
            if (result.data.length > 0) {
                try {
                    setData(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);

                }
            } else {
                alertErrorMessage("No Data Found");


            }
        });
    }


    console.log(data, "datadatadata");

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"> <i className="fa fa-user-check" ></i></div>
                                        Rejected Kyc
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    {data.length === 0 ? (
                        ""
                    ) : (
                        <div className="filter_bar" >
                            <form className="row" >
                                <div className="mb-3 col ">
                                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={dateFrom} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3 col ">
                                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={dateTo} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3 col ">
                                    <div className="row" >
                                        <div className="col" >
                                            <button className="btn btn-indigo btn-block w-100" type="button" onClick={() => handleExportData(dateFrom, dateTo)} disabled={!dateFrom || !dateTo}>Search</button>
                                        </div>
                                        <div className="col" >
                                            <button className="btn btn-indigo btn-block w-100" type="button" onClick={handleData}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>)}
                    <div className="card mb-4">
                        <div className="card-header">Rejected Kyc List
                            {data.length === 0 ? (
                                ""
                            ) : (
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download me-3"></i>Export</button>
                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={data} className="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>)}
                        </div>
                        <div className="card-body">
                            {data.length === 0 ? (
                                <h6 className="ifnoData">
                                    <img src="assets/img/no-data.png" /> <br /> No Data
                                    Available
                                </h6>
                            ) : (
                                <div className="table-responsive">
                                    <ToolkitProvider
                                        hover
                                        bootstrap4
                                        keyField='_id'
                                        columns={columns}
                                        data={data}
                                        exportCSV
                                        search
                                    >
                                        {
                                            props => (
                                                <React.Fragment>
                                                    <SearchBar {...props.searchProps} />
                                                    <BootstrapTable
                                                        hover
                                                        bootstrap4
                                                        keyField='_id'
                                                        columns={columns}
                                                        data={data}
                                                        pagination={pagination}
                                                        filter={filterFactory()}
                                                        {...props.baseProps}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>)}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default RejectedKyc;