import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../../customComponent/Header";
import HomePage from "../HomePage";
import SubadminList from "../SubadminList";
import AddsubAdmin from "../AddsubAdmin";
import TradeList from "../TradeList";
import AddTrade from "../AddTrade";
import PendingKyc from "../PendingKyc";
import ApprovedKyc from "../ApprovedKyc";
import CurrencyManagement from "../CurrencyManagement";
import CurrencypairManagement from "../CurrencypairManagement"
import OrderManagement from "../OrderManagement";
import TradingReport from "../TradingReport";
import Notification from "../Notification";
import EmailTemplate from "../EmailTemplate";
import ContentManager from "../ContentManager";
import Setting from "../Setting";
import MasterWallet from "../MasterWallet";
import UserWalletManagement from "../UserWalletManagement";
import FundsManagement from "../FundsCompletedWithdrawal";
import FiatManagement from "../FiatManagement";
import FundsDManagement from "../FundsCompletedDeposit";
import FiatDManagement from "../FiatDManagement";
import SupportPage from "../SupportPage";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BannerManagement from "../BannerManagement";
import StakingDetails from "../StakingDetails";
import RejectedKyc from "../RejectedKyc";
import TradersBalance from "../ExchangeWalletManagement";
import TradingCommision from "../TradingCommision";
import OrderBook from "../OrderBook";
import FundsPendingDeposit from "../FundsPendingDeposit";
import FundsPendingWithdrawal from "../FundsPendingWithdrawal";
import FundsCancelledWithdrawal from "../FundsCancelledWithdrawal";
import WithdrawalFees from "../WithdrawalFees";
import MiscellaneousPage from "../MiscellaneousPage";
import LiqualityWallet from "../LiquitdityWallet";


const DashboardPage = () => {
    const [activeTab, setActiveTab] = useState('homepage');
    const [coinNameList, setCoinNameList] = useState([]);

    const myPermission = sessionStorage.getItem('permissions');
    const userType = sessionStorage.getItem('userType');
    // const userType = '1';
    const Permissions = myPermission ? JSON.parse(myPermission) : [];

    // const permissions = [1,2,3,4,5,6,7,8,9,10,11,12];
    const user_Id = sessionStorage.getItem('userId');

    const permissions = Permissions.map(item => item.value)

    useEffect(() => {
        // handleCoinTransfer();
        // handleGetreceive(user_Id);

    }, []);

    const handleCoinTransfer = async () => {
        await AuthService.coinTransfer().then(async result => {
            if (result?.success) {
                setCoinNameList(result?.data)
            } else {
                /*  alertErrorMessage(result.message); */
            }
        })
    }

    const handleGetreceive = async (coinName) => {
        await AuthService.getReceives(coinName, user_Id).then(async result => {

            if (result.success) {
                try {
                    // alertSuccessMessage(result.message);
                } catch (error) {
                    // alertErrorMessage(error);
                }
            } else {
                // alertErrorMessage(result.message);
            }

        });

    }

    return (
        <>
            <Header />
            <div id="layoutSidenav" >
                <div id="layoutSidenav_nav">
                    <nav className="sidenav shadow-right sidenav-light">
                        <div className="sidenav-menu">
                            <div className="nav accordion" id="accordionSidenav">
                                <div className="sidenav-menu-heading">pages</div>

                                <a href="/dashboard" className="nav-link collapsed" onClick={() => setActiveTab('homepage')}>
                                    <div className="nav-link-icon"><i className="fa fa-th"></i></div>
                                    Dashboards
                                </a>

                                {userType === '1' ?
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSubAdmin" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-user-friends"></i></div>
                                            Sub Admin
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseSubAdmin" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('listsubadmin')}>Sub Admin List</Link>
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('addsubadmin')}>Add New</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(1) || userType === '1' ?
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTraders" aria-expanded="false" aria-controls="collapseTraders">
                                            <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                                            Traders
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseTraders" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('tradelist')}>Traders List</Link>
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('addnewtrade')}>Add New Trader </Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(2) || userType === '1' ?
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseKyc" aria-expanded="false" aria-controls="collapseKyc">
                                            <div className="nav-link-icon"><i className="fa fa-check-circle"></i></div>
                                            KYC Manager
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseKyc" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('pendingkyc')}>Pending KYC</Link> */}
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('approvedkyc')}>Approved KYC</Link>
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('RejectedKyc')}>Rejected KYC</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(3) || userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('currencymanagement')}>
                                        <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Currency Management
                                    </Link>
                                    : null
                                }

                                {permissions.includes(4) || userType === '1' ?
                                    <span className="nav-link collapsed" onClick={() => setActiveTab('masterwallet')}>
                                        <div className="nav-link-icon"><i className="fa fa-prescription"></i></div>
                                        MasterWallet
                                    </span>
                                    : null
                                }


                                {permissions.includes(5) || userType === '1' ?
                                    <span className="nav-link collapsed" onClick={() => setActiveTab('liqualitywallet')}>
                                        <div className="nav-link-icon"><i className="fa fa-prescription"></i></div>
                                        Liquidity Wallet
                                    </span>
                                    : null
                                }

                                {permissions.includes(6) || userType === '1' ?
                                    <span className="nav-link collapsed" onClick={() => setActiveTab('currencypair')}>
                                        <div className="nav-link-icon"><i className="fa fa-prescription"></i></div>
                                        Currency Pair Management
                                    </span>
                                    : null
                                }


                                {permissions.includes(7) || userType === '1' ?
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFundsManagement" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                            Funds Deposit Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseFundsManagement" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('fundsManagement')}>Withdrawal</Link> */}
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('fundsDManagement')}>Completed Deposit</Link>
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('FundsPendingDeposit')}>Pending Deposit</Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(7) || userType === '1' ?
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefundsWithdrawal" aria-expanded="false" aria-controls="collapsefundsWithdrawal">
                                            <div className="nav-link-icon"><i className="fab fa-google-wallet"></i></div>
                                            Funds Withdrawal Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapsefundsWithdrawal" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('fundsManagement')}>Completed Withdrawal</Link>
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('FundsPendingWithdrawal')}>Pending Withdrawal</Link>
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('FundsCancelledWithdrawal')}>Cancelled Withdrawal</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }
                                {permissions.includes(8) || userType === '1' ?
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTradingCommision" aria-expanded="false" aria-controls="collapseTradingCommision">
                                            <div className="nav-link-icon"><i className="fab fa-google-wallet"></i></div>
                                            Exchange Profit
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseTradingCommision" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('TradingCommision')}>   Trading Commision</Link>
                                                <Link className="nav-link" to="" onClick={() => setActiveTab('WithdrawalFees')}> Withdrawal Fees</Link>
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('MiscellaneousPage')}>Miscellaneous Withdrawal </Link> */}

                                            </nav>
                                        </div>

                                    </>
                                    : null
                                }

                                {permissions.includes(9) || userType === '1' ? (
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab("tradersbalance")}>
                                        <div className="nav-link-icon"><i className="fa fa-wallet"></i></div>
                                        Exchange Wallet Management
                                    </Link>
                                ) : null}

                                {/* {permissions.includes(4) || userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('userwallet')}>
                                        <div className="nav-link-icon"><i className="fa fa-wallet"></i></div>
                                        User Wallet Management
                                    </Link>
                                    : null
                                } */}



                                {/* {permissions.includes(6) || userType === '1'?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('order')}>
                                        <div className="nav-link-icon"><i className="fa fa-chart-bar"></i></div>
                                        Order Management
                                    </Link>
                                    : null
                                } */}

                                {permissions.includes(10) || userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('tradingfeereport')}>
                                        <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                                        Market Trades
                                    </Link>
                                    : null
                                }
                                {permissions.includes(11) || userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('OrderBook')}>
                                        <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                        OrderBook
                                    </Link>
                                    : null
                                }



                                {permissions.includes(12) || userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('notification')}>
                                        <div className="nav-link-icon"><i className="fa fa-bell"></i></div>
                                        Notifications Management
                                    </Link> : null
                                }

                                {/* {permissions.includes(10) ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('email')}>
                                        <div className="nav-link-icon"><i className="fa fa-envelope"></i></div>
                                        Email Template
                                    </Link>
                                    : null
                                } */}

                                {/*  <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('Reports')}>
                                    <div className="nav-link-icon"><i className="fa fa-download"></i></div>
                                    Download Reports
                                </Link> */}

                                {/* {userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('staking')}>
                                        <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                        Staking List
                                    </Link>
                                    : ""
                                } */}

                                {/* {userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('bannerManagement')}>
                                        <div className="nav-link-icon"><i className="fa fa-image"></i></div>
                                        Banner Management
                                    </Link>
                                    : null
                                } */}


                            </div>
                        </div>
                    </nav >
                </div >

                {activeTab === 'homepage' &&
                    <HomePage />
                }
                {activeTab === 'listsubadmin' &&
                    <SubadminList />
                }
                {activeTab === 'addsubadmin' &&
                    <AddsubAdmin />
                }
                {activeTab === 'tradelist' &&
                    <TradeList />
                }
                {activeTab === 'addnewtrade' &&
                    <AddTrade />
                }
                {activeTab === 'pendingkyc' &&
                    <PendingKyc />
                }
                {activeTab === 'approvedkyc' &&
                    <ApprovedKyc />
                }
                {activeTab === 'currencymanagement' &&
                    <CurrencyManagement />
                }
                {activeTab === 'masterwallet' &&
                    <MasterWallet userList={coinNameList} />
                }

                {activeTab === 'liqualitywallet' &&
                    <LiqualityWallet />
                }
                {activeTab === 'userwallet' &&
                    <UserWalletManagement />
                }
                {activeTab === 'currencypair' &&
                    <CurrencypairManagement />
                }
                {activeTab === 'order' &&
                    <OrderManagement />
                }
                {activeTab === 'tradingfeereport' &&
                    <TradingReport />
                }
                {activeTab === "tradersbalance" && <TradersBalance />}
                {activeTab === 'fundsManagement' &&
                    <FundsManagement />
                }
                {activeTab === 'FundsCancelledWithdrawal' &&
                    <FundsCancelledWithdrawal />
                }
                {activeTab === 'FundsPendingWithdrawal' &&
                    <FundsPendingWithdrawal />
                }
                {activeTab === 'fundsDManagement' &&
                    <FundsDManagement />
                }
                {activeTab === 'WithdrawalFees' &&
                    <WithdrawalFees />
                }
                {activeTab === 'MiscellaneousPage' &&
                    <MiscellaneousPage />
                }
                {activeTab === 'fiatManagement' &&
                    <FiatManagement />
                }
                {activeTab === 'fiatDManagement' &&
                    <FiatDManagement />
                }

                {activeTab === 'notification' &&
                    <Notification />
                }
                {activeTab === 'email' &&
                    <EmailTemplate />
                }
                {activeTab === 'content' &&
                    <ContentManager />
                }
                {activeTab === 'setting' &&
                    <Setting />
                }
                {activeTab === 'staking' &&
                    <StakingDetails />
                }
                {activeTab === 'bannerManagement' &&
                    <BannerManagement />
                }
                {activeTab === 'RejectedKyc' &&
                    <RejectedKyc />
                }
                {activeTab === 'TradingCommision' &&
                    <TradingCommision />
                }
                {activeTab === 'OrderBook' &&
                    <OrderBook />
                }
                {activeTab === 'FundsPendingDeposit' &&
                    <FundsPendingDeposit />
                }
            </div>
        </>
    )
}

export default DashboardPage;