import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "./style.css";
import VerifyKyc from "../VerifyKyc";
import { CSVLink } from "react-csv";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";

const PendingKyc = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const [activeScreen, setActiveScreen] = useState("pending");
  const [userId, setUserId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [userKycData, setuserKycData] = useState();
  function cityFormatter(cell, row) {
    return row?.kycdata?.pancard_number;
  }
  function userIdFormatter(cell, row) {
    return row?.kycdata?.userId;
  }
  function panFormatter(cell, row) {
    return row?.kycdata?.pancard_number;
  }

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button className="verifybtn" onClick={() => {
        setUserId(row?._id); setuserKycData(row?.kycdata, "clicked"); setActiveScreen("detail");
      }}>
        View
      </button>
    );
  };

  function imageFormatter(cell, row) {
    return (
      <img style={{ width: "40%", height: "auto" }} className="table-img" src={ApiConfig.appUrl + row?.user_selfie} alt="Selfie" />
    );
  }

  const dateFilter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };

  const columns = [
    { dataField: "_id", text: "ID" },
    { dataField: "firstName", text: "FirstName", sort: true },
    { dataField: "lastName", text: "LastName", sort: true },
    /* { dataField: 'kyctype', text: 'KycType', sort: true, }, */
    { dataField: "mobileNumber", text: "Mobile Number", sort: true },
    { dataField: "pancard_number", text: "Pan Number", sort: true, formatter: cityFormatter, },
    { dataField: "selfiePath", text: "Selfie", sort: true, formatter: imageFormatter, },
    { dataField: "createdAt", text: "Date", sort: true, formatter: dateFilter },
    { dataField: "Action", text: "Action", formatter: linkFollow },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getdata().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data.reverse());
          setDateFrom("");
          setDateTo("");
        } catch (error) {
          alertErrorMessage(error);

        }
      } else {
        LoaderHelper.loaderStatus(false);
        /* alertErrorMessage(result.message); */
      }
    });
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "dateFrom":
        setDateFrom(event.target.value);
        break;
      case "dateTo":
        setDateTo(event.target.value);
        break;
      default:
    }
  };

  const handleExportData = async (dateFrom, dateTo) => {
    await AuthService.exportPandingList(dateFrom, dateTo).then(
      async (result) => {
        if (result.data.length > 0) {
          try {
            setData(result.data.reverse());
          } catch (error) {
            alertErrorMessage(error);

          }
        } else {
          alertErrorMessage("No Data Found");

        }
      }
    );
  };

  return activeScreen === "pending" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className=" fa fa-user-slash"></i>
                    </div>
                    Pending Kyc
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          {data.length === 0 ? (
            ""
          ) : (
            <div className="filter_bar">
              <form className="row">
                <div className="mb-3 col ">
                  <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={dateFrom} onChange={handleInputChange} />
                </div>
                <div className="mb-3 col ">
                  <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={dateTo} onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 col ">
                  <div className="row">
                    <div className="col">
                      <button className="btn btn-indigo btn-block w-100" type="button" onClick={() => handleExportData(dateFrom, dateTo)} disabled={!dateFrom || !dateTo}                      >
                        Search
                      </button>
                    </div>
                    <div className="col">
                      <button className="btn btn-indigo btn-block w-100" type="button" onClick={handleData}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          <div className="card mb-4">
            <div className="card-header">
              Pending Kyc List
              {data.length === 0 ? (
                ""
              ) : (
                <div className="dropdown">
                  <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-download me-3"></i>Export
                  </button>
                  <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                    <CSVLink data={data} className="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              )}
            </div>
            <div className="card-body">
              <>
                {data.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="assets/img/no-data.png" /> <br /> No Data
                    Available
                  </h6>
                ) : (
                  <div className="table-responsive">
                    <ToolkitProvider hover bootstrap4 keyField="_id" columns={columns} data={data} exportCSV search={{
                      afterSearch: (newResult) => console.log(newResult),
                    }}>
                      {(props) => (
                        <React.Fragment>
                          <SearchBar {...props.searchProps} />
                          <BootstrapTable hover bootstrap4 keyField="_id" columns={columns} data={data} pagination={pagination} filter={filterFactory()}
                            {...props.baseProps} />
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <VerifyKyc userId={userId} kycData={userKycData} />
  );
};
export default PendingKyc;
