import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import moment from "moment";

const FiatManagement = () => {
    const { SearchBar } = Search;
    const [fiatWithdraw, setFiatWithdraw] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-success btn-sm me-2" onClick={() => handleApprove(row?._id, row?.userId)}>Approve</button>

                <button className="btn btn-danger btn-sm " onClick={() => handleDenied(row?._id, row?.userId)}>Denied</button>

            </>
        );
    };


    const linkStatus = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <span className={`badge rounded-pill ${row?.status === 'PENDING' ? "bg-warning" : row?.status === 'CANCELLED' ? "bg-danger" : row?.status === 'APPROVE' ? "bg-success" : ""} `}>{row?.status}</span>
            </>
        );
    };

    const dateFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {moment(row?.createdAt).format('Do MMMM YYYY, h:mm: a')}
            </>
        );
    };

    const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.firstName + ' ' + row?.lastName}
            </>
        );
    };

    const columns = [
        { dataField: 'firstName', text: 'User Name', formatter: nameFormatter },
        { dataField: 'emailId', text: 'Email', sort: true, },
        { dataField: 'amount', text: 'Amount', sort: true, },
        { dataField: 'transaction_type', text: 'Payment Type', sort: true, },
        { dataField: 'createdAt', text: 'Date & Time', sort: true, formatter: dateFilter },
        { dataField: 'status', text: 'Status', sort: true, formatter: linkStatus },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
        handleFiatWithdraw()
    }, []);

    const handleFiatWithdraw = async () => {
        LoaderHelper.loaderStatus(true)
        await AuthService.getFiatWithdraw().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    setFiatWithdraw(result.data.reverse());
                    setDateFrom("");
                    setDateTo("");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage("No data found");
            }
        });
    }

    const handleDenied = async (id, userId) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.handleFundDenied(id).then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result.success) {
                handleFiatWithdraw();
            } else {
                alertErrorMessage(result.data.msg)
            }
        })
    }

    const handleApprove = async (id, userId) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.handleFundApprove(id).then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result.success) {
                handleFiatWithdraw();
            } else {
                alertErrorMessage(result.data.msg)
            }
        })
    }

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "dateFrom":
                setDateFrom(event.target.value);
                break;
            case "dateTo":
                setDateTo(event.target.value);
                break;
            default:
        }
    }

    const handleExportData = async (dateFrom, dateTo) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.exportFiatManagement(dateFrom, dateTo).then(async result => {
            if (result.data.length > 0) {
                LoaderHelper.loaderStatus(false)
                try {
                    setFiatWithdraw(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage("No Data Found");
            }
        });
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-feather"></i></div>
                                        Fiat Withdrawal
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Main page content */}
                <div className="container-xl px-4 mt-n10">
                    {fiatWithdraw.length === 0 ? "" :
                        <div className="filter_bar" >
                            <form className="row" >
                                <div className="mb-3 col ">
                                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={dateFrom} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3 col ">
                                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={dateTo} onChange={handleInputChange} />
                                </div>
                                <div className="mb-3 col ">
                                    <div className="row" >
                                        <div className="col" >
                                            <button className="btn btn-indigo btn-block w-100" type="button" onClick={() => handleExportData(dateFrom, dateTo)} disabled={!dateFrom || !dateTo}>Search</button>
                                        </div>
                                        <div className="col" >
                                            <button className="btn btn-indigo btn-block w-100" type="button" onClick={handleFiatWithdraw}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    <div className="card mb-4">
                        <div className="card-header">
                            Fiat Withdrawal Details
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-download me-3"></i>  Export
                                </button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={fiatWithdraw} className="dropdown-item">Export as CSV</CSVLink>
                                </div>
                            </div>
                        </div>

                        {fiatWithdraw.length === 0 ? (
                            <h6 className="ifnoData">
                                <img src="assets/img/no-data.png" /> <br />
                                No Data Available
                            </h6>
                        ) : (
                            <div className="card-body mt-3">
                                <table /* className="table table-bordered" */ width="100%">
                                    <ToolkitProvider
                                        hover
                                        bootstrap4
                                        keyField='_id'
                                        columns={columns}
                                        data={fiatWithdraw}
                                        exportCSV
                                        search={{
                                            afterSearch: (newResult) => console.log(newResult)
                                        }}
                                    >
                                        {
                                            props => (
                                                <React.Fragment>
                                                    <SearchBar {...props.searchProps} />
                                                    <BootstrapTable
                                                        hover
                                                        bootstrap4
                                                        keyField='_id'
                                                        columns={columns}
                                                        data={fiatWithdraw}
                                                        pagination={pagination}
                                                        filter={filterFactory()}
                                                        {...props.baseProps}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    </ToolkitProvider>
                                </table>
                            </div>)}
                    </div>
                </div>
            </main>
        </div>
    )
}

export default FiatManagement;