import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { parse } from "date-fns";

const TradingReport = () => {

    const { SearchBar } = Search;
    const [tradingReport, setTradingReport] = useState([]);

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.fee && parseFloat(row?.fee?.toFixed(8))}
            </>
        );
    };
    const QuantityFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.quantity && parseFloat(row?.quantity?.toFixed(8))}
            </>
        );
    };
    const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <>{moment(row?.updatedAt).format("DD/MM/YYYY")}</>;
    };
    const columns = [
        { dataField: 'updatedAt', text: 'Date/Time', formatter: dateFormatter, },
        { dataField: 'order_id', text: 'Order Id', },
        { dataField: 'currency', text: 'Currency', },
        { dataField: 'order_type', text: 'Order Type', },
        { dataField: 'fee', text: 'Fee', sort: true, formatter: linkFollow },
        { dataField: 'price', text: 'Price', sort: true, },
        { dataField: 'quantity', text: 'Quantity', sort: true, formatter: QuantityFormatter },
        { dataField: 'side', text: 'Side', },
        { dataField: 'tds', text: 'TDS', },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
        handleTradingReport()
    }, []);

    const handleTradingReport = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.tradeHistory().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setTradingReport(result?.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                /* alertErrorMessage("Something Went Wrong"); */
            }
        });
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Trading History
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Main page content */}
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Trading Details
                            {tradingReport.length === 0 ? "" :
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export{" "}
                                    </button>
                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={tradingReport} className="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            }
                        </div>
                        <>
                            {
                                tradingReport.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" />
                                    <br />No Data Available</h6> :
                                    <div className="card-body">
                                        <table className="" width="100%" >
                                            <ToolkitProvider
                                                hover
                                                bootstrap4
                                                keyField='_id'
                                                columns={columns}
                                                data={tradingReport}
                                                exportCSV
                                                search={{
                                                    afterSearch: (newResult) => console.log(newResult)
                                                }}>
                                                {
                                                    props => (
                                                        <React.Fragment>
                                                            <SearchBar {...props.searchProps} />
                                                            <BootstrapTable
                                                                hover
                                                                bootstrap4
                                                                keyField='_id'
                                                                columns={columns}
                                                                data={tradingReport}
                                                                pagination={pagination}
                                                                filter={filterFactory()}
                                                                {...props.baseProps}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </table>
                                    </div>
                            }
                        </>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default TradingReport;