import React, { useState, useEffect } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { $ } from "react-jquery-plugin";
import { CSVLink } from "react-csv";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const UserWalletManagement = () => {

  const [userWalletList, setUserWalletList] = useState([]);
  const [coinName, setCoinName] = useState("BNB");
  const [coinList, setCoinList] = useState([]);
  const [coinId, setCoinId] = useState([]);
  const [walletTo, setWalletTo] = useState("");
  const [walletFrom, setWalletFrom] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedDeposit, setSelectedDeposit] = useState("");
  const [requestOtp, setRequestOtp] = useState("");

  const user_Id = sessionStorage.getItem("userId");

  useEffect(() => {
    // handleUserWalletList("BNB");
    // handleCoinList();
  }, []);
  const { SearchBar } = Search;

  const handleUserWalletList = async (coinName) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserWalletList(coinName).then(async (result) => {

      if (result.data.length > 0) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserWalletList(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };

  const handleCoinList = async () => {
    await AuthService.getCoinList().then(async (result) => {

      if (result.data.length > 0) {
        try {
          setCoinList(result.data);
          handleAdmincoinaddress(coinName);
        } catch (error) {
          alertErrorMessage(error);

        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handleAdmincoinaddress = async (coinName) => {
    await AuthService.getAdmincoinaddress(coinName, user_Id).then(
      async (result) => {
        // 
        if (result.success) {
          try {
            setCoinId(result?.data?.coinId);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleDeposit = (item) => {
    setSelectedDeposit(item);
  };

  const handleGetOtp = async (signId) => {
    await AuthService.getUpiOtp(signId).then(async (result) => {
      if (result.message === "OTP Sent.") {
        try {
          alertSuccessMessage("OTP sent to your emailId");
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(
          "EmailId does not exist. Please update your emailId."
        );
      }
    });
  };



  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => {
            {
              showTransfer();
              setWalletFrom(row?.addr);
            }
          }}
        >
          Transfer
        </button>
        <button
          type="button"
          className="btn btn-sm btn-dark ms-2"
          //   data-bs-toggle="modal"
          //   data-bs-target="#qrCode"
          onClick={() => {
            {
              showTransferQr();
              handleDeposit(row?.addr);
            }
          }}
        >
          QR Code
        </button>
      </div>
    );
  };

  const linkFollow2 = (cell, row, rowIndex, formatExtraData) => {
    return <div>{row?.addr.BEP20 ? row?.addr.BEP20 : ""}</div>;
  };

  const linkFollow3 = (cell, row, rowIndex, formatExtraData) => {
    return <div>{row?.addr.BEP20 ? Object.keys(row?.addr) : ""}</div>;
  };

  //   const resetInputChange = () => {
  //     setCoinId("");
  //     user_Id("");
  //     setWalletTo("");
  //     setRequestOtp("");
  //     setAmount("");
  //   };

  const handleUserWalletTransfer = async (
    coinId,
    user_Id,
    walletTo,
    requestOtp,
    amount
  ) => {
    LoaderHelper.loaderStatus(false);
    await AuthService.walletTransfer(
      coinId,
      user_Id,
      walletTo,
      requestOtp,
      amount
    ).then(async (result) => {
      // 

      if (result.success) {
        LoaderHelper.loaderStatus(false);
        $("#transfer_modal").modal("hide");
        alertSuccessMessage(result.message);
        // resetInputChange();
        setWalletTo("");
        setRequestOtp("");
        setAmount("");
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const showTransfer = () => {
    $("#transfer_modal").modal("show");
  };

  const showTransferQr = () => {
    $("#qrCode_modal").modal("show");
  };

  const columns = [
    {
      dataField: "addr",
      text: "Wallet Address" /* , formatter: linkFollow2 */,
    },
    { dataField: "amount", text: "Amount", sort: true },
    // { dataField: 'chain', text: 'Chain', formatter: linkFollow3 },
    { dataField: "Action", text: "Action", formatter: linkFollow },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,

    onPageChange: function (page, sizePerPage) {


    },
    onSizePerPageChange: function (page, sizePerPage) {


    },
  });

  const copyCode = () => {
    navigator.clipboard
      .writeText(selectedDeposit)
      .then(() => {
        alertSuccessMessage("Copied!!");
      })
      .catch(() => {
        alertErrorMessage("Something went wrong");
      });
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-wallet"></i>
                      </div>
                      User Wallet Management
                    </h1>
                  </div>
                  <div className="col-auto mt-4">
                    <select
                      className="form-control form-control-solid form-select form-select-dark"
                      id="exampleFormControlSelect1"
                      value={coinName}
                      onChange={(e) => setCoinName(e.target.value)}
                    >
                      <option value="" selected="selected" hidden="hidden">
                        Choose here
                      </option>
                      {coinList.length > 0
                        ? coinList.map((item, index) => (
                          <option>{item?.shortName}</option>
                        ))
                        : undefined}
                    </select>
                    <button
                      className="btn btn-success btn-block w-100 mt-3"
                      type="button"
                      onClick={() => handleAdmincoinaddress(coinName)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    User Wallet Details
                    <div className="dropdown">
                      <button
                        className="btn btn-dark btn-sm dropdown-toggle"
                        id="dropdownFadeInUp"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Export
                      </button>
                      <div
                        className="dropdown-menu animated--fade-in-up"
                        aria-labelledby="dropdownFadeInUp"
                      >
                        <CSVLink className="dropdown-item" data={userWalletList}>
                          Export as CSV
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <ToolkitProvider
                            hover
                            bootstrap4
                            keyField="_id"
                            columns={columns}
                            data={userWalletList}
                            search={{
                              afterSearch: (newResult) =>
                                console.log(newResult),
                            }}
                          >
                            {(props) => (
                              <React.Fragment>
                                <SearchBar {...props.searchProps} />
                                <BootstrapTable
                                  hover
                                  bootstrap4
                                  keyField="_id"
                                  columns={columns}
                                  data={userWalletList}
                                  pagination={pagination}
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* transfer_modal modal data */}
      <div
        className="modal"
        id="transfer_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="transfer_modal_modalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Transfer Amount
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> From Address </label>
                  <input
                    className="form-control  form-control-solid input-copy"
                    value={walletFrom}
                    type="text"
                    disabled
                    Placeholder="Enter Address"
                    onChange={(e) => setWalletFrom(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> To Address </label>
                  <input
                    className="form-control  form-control-solid input-copy"
                    value={walletTo}
                    type="text"
                    Placeholder="Enter Address"
                    onChange={(e) => setWalletTo(e.target.value)}
                  ></input>
                </div>
                {/*  */}
                <div className="field-box field-otp-box share mb-3 mt-3">
                  <label className="small mb-1">OTP </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                      value={requestOtp}
                      onChange={(e) => setRequestOtp(e.target.value)}
                    />

                    <button
                      type="button"
                      className="btn btn-sm btn-primary px-3"
                      onClick={() => handleGetOtp("omwinwayworld@gmail.com")}
                    >
                      <span>
                        <i className="ri-file-copy-line"></i> GET OTP{" "}
                      </span>
                    </button>
                  </div>
                </div>

                {/*  */}
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input
                    className="form-control  form-control-solid input-copy"
                    type="text"
                    Placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative">
                  <button
                    className="btn btn-primary   btn-block w-100"
                    type="button"
                    onClick={() =>
                      handleUserWalletTransfer(
                        coinId,
                        user_Id,
                        walletTo,
                        requestOtp,
                        amount
                      )
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* transfer_modal modal data */}
      <div
        className="modal"
        id="qrCode_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="qrCode_modalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h5 className="modal-title " id="exampleModalCenterTitle">
                QR Code
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <img
                src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8`}
                className="qr_img img-fluid"
              />
              {/* <input
                className="shareUrl-input copy_url js-shareUrl text-center"
                type="text"
                readonly="readonly"
                value={selectedDeposit}
                onClick={() => {
                  navigator.clipboard.writeText(selectedDeposit);
                }}
              /> */}
              <div className="field-box field-otp-box share mb-3 mt-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    readonly="readonly"
                    value={selectedDeposit}
                  />

                  <button
                    className="btn btn-sm btn-primary px-3"
                    //className="input-group-text"
                    onClick={() => {
                      copyCode();
                    }}
                  >
                    <span>
                      <i className="ri-file-copy-line"></i> Copy{" "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserWalletManagement;
