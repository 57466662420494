import React, { useState, useEffect } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { $ } from "react-jquery-plugin";
import { CSVLink } from "react-csv";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const TradersBalance = () => {
  const [userWalletList, setUserWalletList] = useState([]);
  const [coinName, setCoinName] = useState("USDT");
  const [coinList, setCoinList] = useState([]);
  const [coinId, setCoinId] = useState([]);
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [userId, setUserId] = useState("");


  useEffect(() => {
    handleUserWalletList("USDT");
    handleCoinList();
  }, []);
  const { SearchBar } = Search;

  const handleUserWalletList = async (coinName) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserWalletList(coinName).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserWalletList(result.data);
          setCoinId(result.data[0]?.currency_id)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        // alertErrorMessage(result.message);
      }
    });
  };

  const handleCoinList = async () => {
    await AuthService.coinlist().then(async (result) => {

      if (result.success) {
        try {
          setCoinList(result.data);
        } catch (error) {
          alertErrorMessage(error);

        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => {
            setUserId(row?.userId); showTransfer();
          }}
        >
          Debit/Credit
        </button>
      </div>
    );
  };

  const balanceFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {row?.balance.toFixed(5)}
      </div>
    );
  };

  const lockedBalanceFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {row?.locked_balance.toFixed(5)}
      </div>
    );
  };

  const handleUserWalletTransfer = async (coinId, userId, amount, type) => {
    if (!amount) {
      alertErrorMessage("Please Enter the Amount");
    } else {
      LoaderHelper.loaderStatus(true);
      await AuthService.fundsTransfer(coinId, userId, amount, type).then(
        async (result) => {
          if (result.success) {
            LoaderHelper.loaderStatus(false);
            $("#funds_modal").modal("hide");
            alertSuccessMessage(result.message);
            setAmount("");
          } else {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(result.message);
          }
        }
      );
    }
  };

  const showTransfer = () => {
    $("#funds_modal").modal("show");
  };

  const columns = [
    { dataField: "userId", text: "User Id" },
    { dataField: "emailId", text: "Email Id" },
    { dataField: "short_name", text: "Coin Name" },
    { dataField: "balance", text: "Available", formatter: balanceFormatter },
    { dataField: "locked_balance", text: "Inorder", formatter: lockedBalanceFormatter },
    { dataField: "Action", text: "Action", formatter: linkFollow },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,

    onPageChange: function (page, sizePerPage) {

      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-wallet"></i>
                      </div>
                      Exchange Wallet Management
                    </h1>
                  </div>
                  <div className="col-auto mt-4">
                    <select
                      className="form-control form-control-solid form-select form-select-dark"
                      id="exampleFormControlSelect1"
                      value={coinName}
                      onChange={(e) => setCoinName(e.target.value)}
                    >
                      <option value="" selected="selected" hidden="hidden">
                        Choose here
                      </option>
                      {coinList.length > 0
                        ? coinList.map((item, index) => (
                          <option>{item?.short_name}</option>
                        ))
                        : undefined}
                    </select>
                    <button
                      className="btn btn-success btn-block w-100 mt-3"
                      type="button"
                      onClick={() => handleUserWalletList(coinName)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    Exchange Wallet Details
                    <div className="dropdown">
                      <button
                        className="btn btn-dark btn-sm dropdown-toggle"
                        id="dropdownFadeInUp"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Export
                      </button>
                      <div
                        className="dropdown-menu animated--fade-in-up"
                        aria-labelledby="dropdownFadeInUp"
                      >
                        <CSVLink className="dropdown-item" data={userWalletList}>
                          Export as CSV
                        </CSVLink>
                      </div>
                    </div>
                  </div>

                  {userWalletList.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" />
                    <br />No Data Available</h6> :
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <ToolkitProvider
                              hover
                              bootstrap4
                              keyField="_id"
                              columns={columns}
                              data={userWalletList}
                              search={{
                                afterSearch: (newResult) =>
                                  console.log(newResult),
                              }}
                            >
                              {(props) => (
                                <React.Fragment>
                                  <SearchBar {...props.searchProps} />
                                  <BootstrapTable
                                    hover
                                    bootstrap4
                                    keyField="_id"
                                    columns={columns}
                                    data={userWalletList}
                                    pagination={pagination}
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* funds modal */}
      <div className="modal" id="funds_modal" tabindex="-1" role="dialog" aria-labelledby="funds_modal_modalTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Debit/Credit
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1">Select Type</label>
                  <select className="form-control  form-control-solid input-copy" value={type} onChange={(e) => setType(e.target.value)}>
                    <option>Select</option>
                    <option value="CREDIT">CREDIT</option>
                    <option value="DEBIT">DEBIT</option>
                  </select>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input className="form-control form-control-solid input-copy" type="number" Placeholder="Enter Amount" value={amount}
                    onChange={(e) => setAmount(e.target.value)}>
                  </input>
                </div>
                <div className="form-group  mb-3 position-relative">
                  <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => handleUserWalletTransfer(coinId, userId, amount, type)}>
                    Debit/Credit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* transfer_modal modal data */}
    </>
  );
};

export default TradersBalance;
