import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const MiscellaneousPage = () => {
  const { SearchBar } = Search;
  const [MiscellaneousList, setMiscellaneousList] = useState([]);
  const [allData, setallData] = useState([]);

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("MMM Do YYYY")}</>;
  };


  const columns = [
    { dataField: "createdAt", text: "Date", sort: true, formatter: dateFormatter, },
    { dataField: "emailId", text: "Email Id", sort: true },
    { dataField: "short_name", text: "Coin Name", sort: true },
    { dataField: "chain", text: "Chain", sort: true },
    { dataField: "user_id", text: "User Id", sort: true },
    { dataField: "to_address", text: "Withdrawal Address", sort: true },
    { dataField: 'transaction_hash', text: 'Transaction Hash', },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "status", text: "Status", sort: true },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });


  useEffect(() => {
    MiscellaneousRequest();
  }, []);

  const MiscellaneousRequest = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.miscellaneousRequest().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setMiscellaneousList(result?.data?.reverse());
          setallData(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("Something Went Wrong");
      }
    });
  };
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setMiscellaneousList(filteredData?.reverse())
  }
  const ResetfilterDate = () => {
    setFromDate('')
    setToDate('')
    setMiscellaneousList(allData)
  }



  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Miscellaneous Withdrawal
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main page content */}
        <div className="container-xl px-4 mt-n10">
          <div className="filter_bar">
            <form className="row">
              <div className="mb-3 col ">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateFrom"
                  value={fromDate}
                  onChange={(e) => { setFromDate(e.target.value); }}
                />
              </div>
              <div className="mb-3 col ">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateTo"
                  value={toDate}
                  onChange={(e) => { setToDate(e.target.value); }}
                />
              </div>
              <div className="mb-3 col ">
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={filterDate}
                    >
                      Search
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={ResetfilterDate}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              Miscellaneous Withdrawal
              {MiscellaneousList.length === 0 ? (
                ""
              ) : (
                <div className="dropdown">
                  <button
                    className="btn btn-dark btn-sm dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export{" "}
                  </button>
                  <div
                    className="dropdown-menu animated--fade-in-up"
                    aria-labelledby="dropdownFadeInUp"
                  >
                    <CSVLink data={MiscellaneousList} className="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              )}
            </div>
            <>
              {MiscellaneousList.length === 0 ? (
                <h6 className="ifnoData">
                  <img src="assets/img/no-data.png" /> <br />
                  No Data Available
                </h6>
              ) : (
                <div className="card-body mt-3">
                  <table className="" width="100%">
                    <ToolkitProvider
                      hover
                      bootstrap4
                      keyField="_id"
                      columns={columns}
                      data={MiscellaneousList}
                      exportCSV
                      search={{
                        afterSearch: (newResult) => console.log(newResult),
                      }}
                    >
                      {(props) => (
                        <React.Fragment>
                          <SearchBar {...props.searchProps} />
                          <BootstrapTable
                            hover
                            bootstrap4
                            keyField="_id"
                            columns={columns}
                            data={MiscellaneousList}
                            pagination={pagination}
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </table>
                </div>
              )}
            </>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MiscellaneousPage;
