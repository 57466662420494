import React, { useState, useEffect } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";


const LiqualityWallet = () => {
  const [fCoin, setFCoin] = useState("");
  const [coinList, setCoinList] = useState([]);
  const [coinBalace, setCoinBalance] = useState(undefined);
  const [amount, setAmount] = useState("");
  const [coinId, setCoinId] = useState();

  const userId = sessionStorage.getItem('userId');


  useEffect(() => {
    handleUserWallet();


  }, []);


  const handleUserWallet = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.userLiqWallet(userId).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setCoinList(result.data);

        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleAmountTransfer = async (amount, coinId) => {
    if (!coinId) {
      alertErrorMessage("Please select coin first");
    } else if (!amount) {
      alertErrorMessage("Please Enter the Amount");
    } else {
      LoaderHelper.loaderStatus(true);
      await AuthService.fundsTransfers(amount, coinId, userId).then(
        async (result) => {
          if (result.success) {
            LoaderHelper.loaderStatus(false);
            alertSuccessMessage(result.message);
            setAmount("");
            handleUserWallet();
          } else {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(result.message);
          }
        }
      );
    }
  };


  console.log(fCoin, 'fCoin');
  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-prescription"></i>
                    </div>
                    Liquidity Wallet
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="row justify-content-start">
            <div className="col-xl-7">
              <div className="card mb-4">
                <div className="card-body d-flex justify-content-center flex-column p-4 ">
                  <div className=" col-md-12 form-group mb-3">
                    <label className="small mb-1">
                      Select Coins
                    </label>
                    {/* <select className="form-control form-control-solid form-select" value={fCoin} onChange={(e) => setFCoin(e.target.value)} >
                      {coinList.length > 0
                        ? coinList.map((item, index) => (
                          <option value={item?.short_name}>
                            {item?.short_name}
                          </option>
                        ))
                        : null}
                    </select> */}

                    <select className="form-control form-control-solid form-select" value={fCoin}
                      onChange={(e) => {
                        setFCoin(e.target.value); const selectedCoin = coinList.find((item) => item.short_name === e.target.value);
                        if (selectedCoin) {
                          setCoinId(selectedCoin.currency_id);
                        }
                      }}>
                      <option selected>Please Select Coin</option>
                      {coinList.length > 0
                        ? coinList.map((item, index) => (
                          <option key={index} value={item?.short_name}>
                            {item?.short_name}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                  <div className=" col-md-12 form-group mb-3">
                    <label className="small mb-1">
                      Set Balance
                    </label>
                    <input className="form-control" type="number" Placeholder="Enter Amount"
                      value={amount} onChange={(e) => setAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  < div className="col-md-12 form-group mb-3" >

                    <div className="">
                      {coinList.map((item, index) => (
                        item.short_name === fCoin && (
                          <input className="form-control" type="text" placeholder="Balance" value={item?.liquidity_balance} onChange={(e) => {
                            setCoinBalance(e.target.value);
                          }} />
                        )
                      ))
                      }
                    </div>
                  </div>
                  <div className="form-group ">
                    <button className="btn btn-indigo w-100" type="button" onClick={() =>
                      handleAmountTransfer(amount, coinId)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main >
    </div >
  );
};

export default LiqualityWallet;
