import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const OrderManagement = () => {

  const { SearchBar } = Search;
  const [OrderManagement, setOrderManagement] = useState([]);

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {(row?.amount).toFixed(2)}
      </>
    );
  };

  const linksymbol = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row?.fname + '/' + row?.sname}
      </>
    );
  };

  const linkStatus = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <span className={`badge text-white rounded-pill ${row?.status === 'Pending' ? "bg-warning" : row?.status === 'Cancelled' ? "bg-danger" : row?.status === 'Completed' ? "bg-success" : ""} `}>{row?.status}</span>
      </>
    );
  };


  const columns = [
    { dataField: '_id', text: 'Order ID' },
    { dataField: 'orderType', text: 'Order Type', sort: true, },
    { dataField: 'email', text: 'Email ID', sort: true, },
    { dataField: 'symbol', text: 'Pair', sort: true, formatter: linksymbol },
    { dataField: 'quantity', text: 'Quantity', sort: true, },
    { dataField: 'price', text: 'Price', sort: true, },
    { dataField: 'Amount', text: 'Amount', sort: true, formatter: linkFollow },
    { dataField: 'status', text: 'Status', sort: true, formatter: linkStatus },
  ]


  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 7,
    lastPageText: '>>',
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });


  useEffect(() => {
    // handleOrderManagement()
  }, []);

  const handleOrderManagement = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOrderManagement().then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.data.length > 0) {
        try {
          setOrderManagement(result?.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        /* alertErrorMessage("Something Went Wrong"); */
      }
    });
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-chart-bar"></i>
                    </div>
                    Order Management
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              Order Management Details
              {OrderManagement.length === 0 ? "" :
                <div className="dropdown">
                  <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Export{" "}
                  </button>
                  <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                    <CSVLink data={OrderManagement} className="dropdown-item">Export as CSV</CSVLink>
                  </div>
                </div>
              }
            </div>
            <div className="card-body mt-3">
              {OrderManagement.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" />
                <br /> No Data Available</h6> :
                <table width="100%">
                  <ToolkitProvider
                    hover
                    bootstrap4
                    keyField='id'
                    columns={columns}
                    data={OrderManagement}
                    exportCSV
                    search={{
                      afterSearch: (newResult) => console.log(newResult)
                    }} >
                    {
                      props => (
                        <React.Fragment>
                          <SearchBar {...props.searchProps} />
                          <BootstrapTable
                            hover
                            bootstrap4
                            keyField='id'
                            columns={columns}
                            data={OrderManagement}
                            pagination={pagination}
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </React.Fragment>
                      )
                    }
                  </ToolkitProvider>
                </table>
              }
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OrderManagement;
