import React, { useState, useEffect } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";


const MasterWallet = () => {
  const [transationHistory, setTransationHistory] = useState([]);
  const [fCoin, setFCoin] = useState("USDT");
  const { SearchBar } = Search;
  const [coinList, setCoinList] = useState([]);
  const [coinBalace, setCoinBalance] = useState(undefined);
  const [genrateAddress, setGenrateAddress] = useState('');
  const userId = sessionStorage.getItem('userId');


  console.log(userId, 'userId');

  useEffect(() => {
    handleCoinList();
    handleCoinBal(fCoin);
    handleWallethistory();
    handleVerify();
  }, []);

  const dateFilter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };

  const SrnoFormate = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {rowIndex + 1}

      </>
    );
  };

  const columns = [

    { dataField: "Srno", text: "Sr.No.", formatter: SrnoFormate },
    { dataField: "short_name", text: "Coin Name", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "transaction_type", text: "Transaction Type", sort: true },
    { dataField: "updatedAt", text: "Date/Time", sort: true, formatter: dateFilter, },
    { dataField: "status", text: "Status", disableSortBy: true },

  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });
  // *******Table Ended ************//


  const handleCoinList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCoinList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setCoinList(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };


  const handleCoinBal = async (fCoin) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.CoinBalance(fCoin, userId).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setCoinBalance(result?.data);
          handleQrgenrate(userId, result?.data?.chain[0])
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleWallethistory = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletHistory(userId).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setTransationHistory(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleQrgenrate = async (id, chins) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.generateQr(id, chins).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setGenrateAddress(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleVerify = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.handleVerifyDeposit(userId).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };


  console.log(genrateAddress, "genrateAddress");

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-prescription"></i>
                    </div>
                    Master Wallet
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="row">
            <div className="col-xl-4">
              <div className="card mb-4">
                <div className="card-body d-flex justify-content-center flex-column p-4 ">
                  <div className=" col-md-12 form-group mb-3">
                    <label className="small mb-1">
                      Select Currency
                    </label>
                    <select className="form-control form-control-solid form-select" value={fCoin} onChange={(e) => setFCoin(e.target.value)} onClick={() =>
                      handleCoinBal(fCoin)}>
                      {coinList.length > 0
                        ? coinList.map((item, index) => (
                          <option value={item?.short_name}>
                            {item?.short_name}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>

                  <div className=" col-md-12 form-group mb-3">
                    <div className="card" >
                      <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${genrateAddress}&choe=UTF-8`} className="img-fluid" />
                    </div>
                  </div>
                  <div className=" col-md-12 form-group mb-3">
                    <input className="form-control text-center " type="text" value={genrateAddress} onClick={() => {
                      navigator.clipboard.writeText(genrateAddress); alertSuccessMessage("Copied")
                    }} readOnly />
                    <small className="mt-2 text-center w-100 d-block" > <small>Click above to copy the Code.</small>  </small>
                  </div>
                  < div className="col-md-12 form-group" >
                    <div className="" >
                      <div>Balance : </div>
                      <h4> {coinBalace?.balance} </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card mb-4">
                <div className="card-body d-flex justify-content-center flex-column p-4 ">
                  <div className="d-flex align-items-center justify-content-start  ">
                    <h5 className="mb-0">Transation History</h5>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <ToolkitProvider hover bootstrap4 keyField="_id" columns={columns} data={transationHistory} search={{
                            afterSearch: (newResult) => console.log(newResult),
                          }}>
                            {(props) => (
                              <React.Fragment>
                                <SearchBar {...props.searchProps} />
                                <BootstrapTable hover bootstrap4 eyField="_id" columns={columns} data={transationHistory} pagination={pagination} filter={filterFactory()}{...props.baseProps} />
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    </div >
  );
};

export default MasterWallet;
